import React from 'react'  
import { Link } from 'react-router-dom'
const QuestionListComponents = (props) => {
  return (
    <>
         { 
         props.item.length > 0 ? props.item.map((queItem, podidx) => { 
           return (
                <div className="step_question one step_1" key={podidx} >
                  <div className="question_block">
                    <div className="question_details">
                      <h3>{queItem.questions_title}</h3>
                      <p>{queItem.summary}</p>
                    </div>
                    <div className="yes_no_block">
                      <p>{queItem.feedback_title}</p>
                      <div className="yes_no_btn">
                        <Link to={{ pathname: '/thankyou-feedback?service='+props.serviceName+'&ledgern='+props.ledgern}} className="btn btn-primary yes_btn" >Yes</Link>
                        <button className="btn btn-primary no_btn" onClick={() => props.nextPageHandler(1) }  disabled={ props.ledgern =="" ? "disabled" : "" }>No</button>
                      </div>
                    </div>
                  </div> 
                  { queItem.video_url ? 
                    <div className="qus_video_block">
                      <h3>Watch video tutorial for troubleshooting</h3>
                      <button type="button" className="btn btn-primary vid_play" onClick={() => props.handleShow(queItem.video_url) }>Video Tutorial</button>
                    </div>
                  :''
                  }
              </div>
          )
      })
        : ""
      }
</>    
  )
}
  export default QuestionListComponents;