import React, { useState, useEffect } from 'react'
import { useParams, useNavigate,useLocation } from 'react-router-dom'
import { findProblemName, fetchProblemQuestionsData } from '../../../services/Front';
import QuestionListComponents from './QuestionComponents';
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
const HomePage = () => { 
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  const [problemsQuestionsData,setProblemsQuestionsData] = useState("");
  const [problemsName, setProblemsName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [checkLedgerNum, setCheckLedgerNum] = useState(false);
  
  const [pagination,setPagination] = useState(1);
  const [page,setPage] = useState(1);
  
  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (Vurl) => {
    setVideoUrl(Vurl);
    setShow(true)
  };  

  const params = useParams();  
  const { search } = useLocation();
  const parameters = new URLSearchParams(search); 
  const probid = params.pid; 
  const ledgern = parameters.get('ledgern');   
  
  const nextPageHandler = (parPage) => {
    const parpage = page + 1;
    setPage(parpage);
  };

  useEffect(() => {
    findProblem();
    getQuestionListData();
    if(ledgern == null){ 
      navigate("/questions");
    }  
    if(ledgern === ""){ 
      navigate("/questions");
    }

  }, [page])
  
  const findProblem = () => {
    setIsLoading(true);
    findProblemName(probid)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setProblemsName(result.data);
        } else {
          setIsLoading(false);
          console.log('something went wrong.');
        }
      }).catch((err) => {
        setIsLoading(false);
        toast.error("Error retrieving problem with id.");
        // console.log(err.message);
      });
  }

  const getQuestionListData = () => {
    setIsLoading(true);
    const pageData = {
      "pid": probid,
      "page": page,
      "pagination": pagination
    }
    fetchProblemQuestionsData(pageData)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          if (result.data.status === false) {
            navigate("/service-entry/" + probid+'?ledgern='+ledgern);
          } else { 
            setProblemsQuestionsData(result.data);
          }
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  }
  return ( 
    <> 
      {isLoading ?
        <div className='loading'>
          <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div>
        </div>
        : ""
      }

      <div className="container">
        <div className="troubleshooting_qus_block">
          <div className="troubleshooting_qus_inner">
            <div className="prob_title">
              <h2>{problemsName.title}</h2>
            </div>
            <div className="qus_steps_wrap" id="qus_steps_wrap">
              <QuestionListComponents item={problemsQuestionsData} nextPageHandler={nextPageHandler} handleShow={handleShow} probid={probid} serviceName={problemsName.slug} ledgern={ledgern} />
            </div>
          </div>
        </div>
      </div> 
      <Modal show={show} onHide={handleClose} className="videoModal">
        <Modal.Header closeButton variant="white">
        </Modal.Header>
        <Modal.Body>
          <div className="video_popup_inner">
            <div className="video_wrap youtube" >
              <iframe frameBorder="0" allowFullScreen="" src={ videoUrl }></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="popup_overlay"></div>

    </>

  )
}

export default HomePage;