const ENV = 'staging'; // local | staging | PROD
var Data = {};
if (ENV == 'local' || ENV == 'staging') {
    Data = {
        BASE_PATH: ENV == 'local' ? "http://localhost:8081/api/" : 'https://csportalapidev.slomins.com/api/',
        Allow_Origin: ENV == 'local' ? "http://localhost:3000/api/" : 'https://csportalapidev.slomins.com/api/',
        FRONT_URL: ENV == 'local' ? "http://localhost:4200/" : 'https://csportaldev.slomins.com/',
        PERMISSION_LIST_API: ENV == 'local' ? "http://alarmsite.local/api/user/permission-list" : 'https://alarmdev.slomins.com/api/user/permission-list',
        AUTH_ADMIN_URL: ENV == 'local' ? "http://localhost:3000" : 'https://portaldev.slomins.com',
        Cookie_Domain: ENV == 'local' ? "localhost" : '.slomins.com',
        SITE_ID: 8,
        API_BASEPATH: ENV == 'local' ? "http://auth.slomins.local/api" : 'https://authdevapi.slomins.com/api',
        Cookie_Name: ENV == 'local' ? "auth_token_local" : 'auth_token_dev',
        STATIC_PAGE_URL: "https://stagingv2.slomins.com/",
        CALLBACK_QUEUE_URL: "https://qa2.slomins.com/QA/Corp/2.7/Corp.svc/CallBack",
        SERVICE_CALBACK_URL: "https://qa2.slomins.com/QA/Corp/2.7/Corp.svc/RequestServiceCall",
        GETCUSTOMERINFORMATION: "https://qa2.slomins.com/QA/Corp/2.7/Corp.svc/GetCustomerInfoV1",
        GETAPPSIDLOGIN: "https://qa2.slomins.com/QA/Corp/2.7/Corp.svc/Login",
        IP_ADDRESS : 'https://authdevapi.slomins.com/api/get-ipaddress'
    };
} else {
    Data = {
        BASE_PATH: "https://csportal.slomins.com/api/",
        Allow_Origin: "https://csportal.slomins.com/api/",
        FRONT_URL: "https://csselfserve.slomins.com/",
        PERMISSION_LIST_API: "https://alarm.slomins.com/api/user/permission-list",
        AUTH_ADMIN_URL: 'https://portal.slomins.com',
        SITE_ID: 8,
        API_BASEPATH: "https://oilinventoryapi.slomins.com/api",
        Cookie_Domain: '.slomins.com',
        Cookie_Name: 'auth_token_prod',
        STATIC_PAGE_URL: "https://www.slomins.com",
        CALLBACK_QUEUE_URL: "https://svcs.slomins.com/PROD/Corp/1.7/Corp.svc/CallBack",
        SERVICE_CALBACK_URL: "https://svcs.slomins.com/PROD/Corp/2.7/Corp.svc/RequestServiceCall",
        GETCUSTOMERINFORMATION: "https://svcs.slomins.com/PROD/Corp/2.7/Corp.svc/GetCustomerInfoV1",
        GETAPPSIDLOGIN: "https://svcs.slomins.com/PROD/Corp/2.7/Corp.svc/Login",
        IP_ADDRESS  : "https://authapi.slomins.com/api/get-ipaddress"
    };
}
export const constant = Data;  