import React from 'react'
import { Link,useParams,useLocation } from 'react-router-dom'
import tick_mark from '../../../assets/img/tick_mark.svg';
import thankyouImg from '../../../assets/img/thankyou-img.png';
const ThankyouFeedback = () => {  
     const { search } = useLocation();
     const parameters = new URLSearchParams(search);  
     const ledgern = parameters.get('ledgern');   
     const service = parameters.get('service'); 
    return (
        <>
            <div className="thankyou_wrap">
                <div className="container">
                    <div className="thankyou_content">
                        <div className="top_msg_wrap text-center">
                            <div className="success_circle_wrap">
                                <div className="success_circle">
                                    <img src={tick_mark} alt="ok" />
                                </div>
                            </div>
                            <div className="thankyou_msg">
                                <h2>Thank you!</h2>
                            </div>
                            <div className="order_info">
                                <p className="text-success">Your issue was resolved successfully</p>
                                <p><img src={ thankyouImg } alt="thank you" /></p>
                            </div>
                            <div className="bottom_sec">
                                <p>Still need help with the services</p>
                                <Link to={{ pathname: '/problems/'+service+'?ledgern='+ledgern}} className="btn btn-primary red_btn">Start Over</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ThankyouFeedback
